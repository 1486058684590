import React, { Component } from "react";

import Flickity from "flickity";
import GoogleMapReact from "google-map-react";
import $ from "jquery";
import { Helmet } from "react-helmet";
import api from "../../services/api";

import {
  Banner,
  Container,
  Descricao,
  Empreendimento,
  Galeria,
  Image,
  Mapa,
  Planta,
  Plantas,
} from "./styles";

import Contato from "../../components/Contato";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

import ArrowRight from "../../assets/images/arrow-point-to-right.svg";
import LocationIcon from "../../assets/images/location.svg";
import PinIcon from "../../assets/images/pin.svg";

window.jQuery = $;
require("@fancyapps/fancybox/dist/jquery.fancybox");

class Empreendimentos extends Component {
  state = {
    maps: {
      center: {
        lat: -25.088296,
        lng: -50.15867,
      },
    },
    empreendimento: null,
    gallery: null,
    loading: false,
    showLoadMoreButton: true,
    plantaId: null,
  };

  componentDidMount() {
    document.title = "Empreendimentos - Construtora Miquelão";
    document.getElementsByTagName("body")[0].style.overflow = "unset";
    this.setState({ loading: true });
    this.getEmpreendimento().then(() => {
      this.setState({ loading: false });
      document.title = `${this.state.empreendimento.title.rendered} - Construtora Miquelão`;
      this.flkty = new Flickity("#plantas", {
        pageDots: false,
        draggable: false,
        wrapAround: true,
      });

      $(document).on("afterClose.fb", () => {
        const plantasEl = document.getElementById("plantas");
        plantasEl.style.display = null;

        this.flkty = new Flickity("#plantas", {
          pageDots: false,
          draggable: false,
          wrapAround: true,
        });
        this.flkty.select(this.state.plantaId, true, true);
      });

      $(document).on("beforeShow.fb", () => {
        if (this.flkty) this.flkty.destroy();
        const plantasEl = document.getElementById("plantas");
        plantasEl.style.display = "none";
      });
    });
    /*eslint-disable */

    $('[data-fancybox="gallery"]').fancybox({
      afterShow: function () {},
      afterClose: function () {
        // fancybox is closed, run myOtherFunct()
      },
    });
  }

  getEmpreendimento = async () => {
    const { params } = this.props.match;
    const response = await api.get(`/empreendimentos?slug=${params.slug}`);

    this.setState({
      empreendimento: response.data[0],
      maps: {
        center: {
          lat: parseFloat(response.data[0].acf.localizacao.lat, 10),
          lng: parseFloat(response.data[0].acf.localizacao.lng, 10),
        },
      },
    });

    if (!!response.data && !!response.data[0].acf.galeria_imagens) {
      const galleryArray = [];
      response.data[0].acf.galeria_imagens.forEach((imagem) => {
        if (galleryArray.length < 6) {
          galleryArray.push(imagem);
        }
      });
      this.setState({ gallery: galleryArray }, () => {
        this.state.gallery.forEach((imagem, index) => {
          $(`[data-fancybox="planta${index}"]`).fancybox({});
        });
      });
    }

    this.setState({ plantas: response.data[0].acf.plantas });
  };

  loadMoreImages = () => {
    const { gallery, empreendimento } = this.state;
    const galleryArray = gallery;
    const initialLength = gallery.length;

    this.flkty.destroy();

    empreendimento.acf.galeria_imagens.forEach((imagem, index) => {
      if (
        index >= galleryArray.length &&
        galleryArray.length < initialLength * 2
      ) {
        galleryArray.push(imagem);
      }
    });

    if (empreendimento.acf.galeria_imagens.length === galleryArray.length) {
      this.setState({ showLoadMoreButton: false });
    }

    this.setState({ gallery: galleryArray }, () => {
      this.flkty = new Flickity("#plantas", {
        pageDots: false,
        draggable: false,
        wrapAround: true,
      });
    });
  };

  render() {
    const { maps, empreendimento, loading, showLoadMoreButton } = this.state;

    return (
      <Container>
        <Helmet>
          <meta
            property="og:image"
            content={
              !!empreendimento && empreendimento.acf.facebook_share
                ? !!empreendimento && empreendimento.acf.facebook_share.url
                : ""
            }
          />
        </Helmet>

        <Header />

        {!!empreendimento && empreendimento.acf.facebook_share ? (
          <img id="share" src={empreendimento.acf.facebook_share.url} />
        ) : (
          ""
        )}

        {!!empreendimento ? (
          <Banner bgImage={empreendimento.acf.banner.imagem.url}>
            <h2>{empreendimento.acf.banner.texto}</h2>
          </Banner>
        ) : (
          <Banner bgImage="https://via.placeholder.com/1000x500?text=+" />
        )}

        <Empreendimento>
          <div className="infos">
            {!!empreendimento && (
              <img
                src={empreendimento.acf.logo.url}
                alt="Logo empreendimento"
              />
            )}

            {!!empreendimento && (
              <span
                className="descricao"
                dangerouslySetInnerHTML={{
                  __html: empreendimento.acf.caracteristicas.texto,
                }}
              />
            )}

            <span className="localizacao">
              <img src={LocationIcon} alt="Localização" />
              {!!empreendimento &&
                `${empreendimento.acf.endereco}, ${empreendimento.acf.endereco_numero} - ${empreendimento.acf.endereco_bairro}`}
            </span>

            <div className="obras">
              {!!empreendimento &&
                empreendimento.acf.andamentos.map((infos) => (
                  <div key={Math.random(100)} className="item">
                    <span>{infos.andamento}</span>
                    <span className="porcentagem">{`${infos.porcentagem} %`}</span>
                    <div
                      className="concluido"
                      style={{ width: `${infos.porcentagem}%` }}
                    />
                  </div>
                ))}
            </div>

            <div className="previsao">
              {!!empreendimento && (
                <span>
                  {new Date().getFullYear() >
                    empreendimento.acf.dt_entrega.slice(0, 4) ||
                  (new Date().getFullYear() <=
                    empreendimento.acf.dt_entrega.slice(0, 4) &&
                    new Date().getMonth() >
                      empreendimento.acf.dt_entrega.slice(4, 6))
                    ? "Entregue em: "
                    : "Previsão de entrega: "}
                  <span>
                    {`${empreendimento.acf.dt_entrega.slice(
                      4,
                      6
                    )}/${empreendimento.acf.dt_entrega.slice(0, 4)}`}
                  </span>
                </span>
              )}
            </div>

            {!!empreendimento && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  !!empreendimento.acf.hotsite_url
                    ? empreendimento.acf.hotsite_url
                    : "#"
                }
              >
                Hotsite <img src={ArrowRight} alt="Seta" />
              </a>
            )}
          </div>
          <div className="imagem">
            {!!empreendimento ? (
              <img src={empreendimento.acf.fachada.url} alt="Empreendimento" />
            ) : (
              <img
                src="https://via.placeholder.com/1000x500?text=+"
                alt="loading"
              />
            )}
          </div>
        </Empreendimento>
        <Descricao>
          <div>
            {!!empreendimento && (
              <h3>{empreendimento.acf.caracteristicas.caracteristica}</h3>
            )}
          </div>
          <div>
            {!!empreendimento && (
              <span
                dangerouslySetInnerHTML={{
                  __html: empreendimento.acf.caracteristicas.texto,
                }}
              />
            )}

            {!!empreendimento && (
              <span>{empreendimento.acf.caracteristicas.titulo}</span>
            )}
          </div>
        </Descricao>
        {!!empreendimento && (
          <Image src={empreendimento.acf.banner.central.url} alt="Image" />
        )}

        {!!empreendimento && !!empreendimento.acf.plantas ? (
          <Plantas id="plantas">
            {empreendimento.acf.plantas.map((planta, index) => (
              <>
                <div key={Math.random(100)} className="carousel-cell">
                  <h3>Plantas tipo</h3>
                  <Planta>
                    <div>
                      <a
                        href={planta.imagem.url}
                        onClick={() => this.setState({ plantaId: index })}
                        data-fancybox={`planta${index}`}
                      >
                        <img src={planta.imagem.url} alt="Planta" />
                      </a>
                    </div>
                    <div>
                      <h4>
                        Planta <span>{planta.titulo}</span>
                      </h4>

                      <div>
                        {!!planta.caracteristicas &&
                          planta.caracteristicas.map((c) => (
                            <div key={Math.random()}>
                              <span>{c.caracteristica}</span>
                            </div>
                          ))}
                      </div>

                      <h4>
                        <span>{`${planta.m2_area}m²`}</span> Área Total
                      </h4>
                    </div>
                  </Planta>
                </div>
              </>
            ))}
          </Plantas>
        ) : null}

        {!!this.state.gallery ? (
          <Galeria>
            <h3>Galeria de Imagens</h3>
            <div>
              {this.state.gallery.map((imagem, index) => {
                if (index % 2 === 0) {
                  return (
                    <a
                      key={imagem.id}
                      href={imagem.url}
                      // onClick={() => this.flkty.destroy()}
                      data-fancybox="gallery"
                      data-caption={imagem.caption}
                    >
                      <img src={imagem.url} alt="Imagem" />
                    </a>
                  );
                }
                return (
                  <a
                    key={imagem.id}
                    href={imagem.url}
                    data-fancybox="gallery"
                    data-caption={imagem.caption}
                    className="image__small"
                  >
                    <img src={imagem.url} alt="Imagem" />
                  </a>
                );
              })}
            </div>
            {!!showLoadMoreButton && (
              <button onClick={this.loadMoreImages}>
                Carregar mais imagens <img src={ArrowRight} alt="Seta" />
              </button>
            )}
          </Galeria>
        ) : null}

        <Mapa>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyAkEaq0XtziJ8BnVpQfHx-Ux2Im71VHb0s",
            }}
            defaultZoom={15}
            center={maps.center}
          >
            <img
              className="pin__icon"
              lat={maps.center.lat}
              lng={maps.center.lng}
              title="Empreendimento"
              src={PinIcon}
              alt="Pin"
            />
          </GoogleMapReact>
        </Mapa>

        <Contato
          empreendimento={!!empreendimento && empreendimento.title.rendered}
        />
        <Footer />
      </Container>
    );
  }
}

export default Empreendimentos;
